html {
    font-size: 16px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "WorkSans-Regular";
    src: local("WorkSans-Regular"),
        url(./theme/fonts/work-sans/WorkSans-VariableFont_wght.ttf)
            format("truetype");
}

@font-face {
    font-family: "Circular";
    src: local("Circular"),
        url(./theme/fonts/circular-std-medium-500.ttf) format("truetype");
}

* {
    margin: 0;
    box-sizing: border-box;
    font-family: WorkSans-Regular;
}

*:focus {
    outline: none;
}

a:visited {
    color: #007bff;
}
